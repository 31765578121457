<template>
  <div class="scan">
    <div 
        style="width:500px; margin: auto;">
      <h2>Votingcode scannen</h2>
      <scan-voting
        @decode="onDecodeVoting"
        @loaded="onLoadedVotingScanner"
        showStart="true"
        showReset="true"
        showChange="true"
      />
      <h3>{{ answer }}</h3>
      <h5>{{ cardnumber }}</h5>
    </div>
  </div>
</template>

<script>
import ScanVoting from "@/components/ScanVoting.vue";
import VotingDataService from "@/services/VotingDataService";

export default ({
  name: 'scan',
  components: {
    ScanVoting
  },
  data() {
    return {
      answer: '',
      cardnumber: '',
      voting: null
    }
  },
  async mounted() {
    if(this.$route.params.votingid) {
      console.log('load voting: ' + this.$route.params.votingid)
      this.voting = await VotingDataService.get(this.$route.params.votingid)
      if(!this.voting) {
        console.log('empty voting: ' + this.$route.params.votingid)
        this.$router.push('/scan')
      }
    }
  },
  methods: {
    async onDecodeVoting (code) {
      this.voting = await VotingDataService.get(code);
      if(this.voting && this.voting.id) {
        this.$router.push('/scan/' + this.voting.id)
      }
      console.log('Voting: ' + this.voting)
    },
    onLoadedVotingScanner () {
      console.log('VotingScanner loaded')
    }, 
    onLoadedCardScanner () {
      console.log('CardScanner loaded')
    } 
  }
})
</script>
