<template>
  <div class="scan">
    <div 
        style="width:500px; margin: auto;">
      <h2>Karten scannen {{ voting.id }}</h2>
      <scan-card
        @decode="onDecodeCard"
        @loaded="onLoadedCardScanner"
        showStart="true"
        showReset="true"
        showChange="true"
      />
      <h3>{{ answer }}</h3>
      <h5>{{ cardnumber }}</h5>
    </div>
  </div>
</template>

<script>
import ScanCard from "@/components/ScanCard.vue";
import VotingDataService from "@/services/VotingDataService";

export default ({
  name: 'scan',
  components: {
    ScanCard,
  },
  data() {
    return {
      answer: '',
      cardnumber: '',
      voting: null
    }
  },
  async mounted() {
    if(this.$route.params.votingid) {
      console.log('load voting: ' + this.$route.params.votingid)
      this.voting = await VotingDataService.get(this.$route.params.votingid)
      if(!this.voting) {
        console.log('empty voting: ' + this.$route.params.votingid)
        this.$router.push('/scan')
      }
    }
  },
  methods: {
    async onDecodeCard (answer, cardnumber) {
      this.answer = answer
      this.cardnumber = cardnumber
      if(!this.voting.cards) {
        this.voting.cards = {}
      }
      this.voting.cards['' + cardnumber] = answer
      let emptyVotes = VotingDataService.new().votes
      let votings = Object.keys(this.voting.cards).reduce((votes, card) => {
        votes[this.voting.cards[card]]++
        return votes
      }, emptyVotes)
      this.voting.votes = votings
      let result =  await VotingDataService.set(this.voting.id, this.voting);
      console.log('set: ' + result)
    },
    onLoadedVotingScanner () {
      console.log('VotingScanner loaded')
    }, 
    onLoadedCardScanner () {
      console.log('CardScanner loaded')
    } 
  }
})
</script>
