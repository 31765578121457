<template>
    <div class="scanner-container">
        <div v-show="!isLoading">
            <video poster="data:image/gif,AAAA" ref="scanner"></video>
            <div class="overlay-element"></div>
            <div class="laser"></div>
        </div>
    </div>
</template>

<script>
import { BrowserQRCodeReader, Exception } from "@zxing/browser";

Math.toDegrees =  rad => rad * 180 / Math.PI;

export default {
    name: "scan-card",
    data() {
        return {
            isLoading: true,
            codeReader: new BrowserQRCodeReader(),
            controls: null,
            isMediaStreamAPISupported:
                navigator &&
                navigator.mediaDevices &&
                "enumerateDevices" in navigator.mediaDevices
        };
    },
    mounted() {
        if (!this.isMediaStreamAPISupported) {
            throw new Exception("Media Stream API is not supported");
        }
        this.start();
        this.$refs.scanner.oncanplay = () => {
            this.isLoading = false;
            this.$emit("loaded");
        };
    },
    beforeDestroy() {
        if(this.controls) {
            this.controls.stop();
        }
    },
    methods: {
        start() {
            this.controls = this.codeReader.decodeFromVideoDevice(
                undefined,
                this.$refs.scanner,
                (result) => {
                    if (result) {
                        // https://stackoverflow.com/questions/7842705/android-zxing-orientation-resultmetadata-is-null-get-rotation-orientation
                        var points = result.getResultPoints();
                        var a= points[1];
                        var b= points[2];
                        // var c= points[0];

                        var z = Math.abs(a.getX()-b.getX());
                        var x = Math.abs(a.getY()-b.getY());
                        var theta = Math.toDegrees(Math.atan(x/z));

                        // Quadrants 0 and 1
                        if(a.getY() > b.getY()) {
                            if(a.getX() > b.getX()) {
                                theta = 90 + (90 - theta);
                            }
                        }
                        // Quadrants 2 or 3
                        else {
                            if (a.getX() > b.getX()) {
                                theta = 180 + theta;
                            }
                            else {
                                theta = 360 - theta;
                            }
                        }
                        var content = result.text.match(/(.)-(\d+)-(\d+)/);
                        var cardtype = content[1];
                        var cardnumber = content[2];
                        var cardside = content[3];
                        var value = '';
                        if(theta > 315 || theta <= 45) {
                            value = 'A';
                        } else
                        if((theta > 135 && theta <= 225)) {
                            value = 'B';
                        } else
                        if((theta > 225 && theta <= 315)) {
                            value = 'C';
                        } else 
                        if((theta > 45 && theta <= 135)) {
                            value = 'D';
                        }
                        // console.log(cardnumber + ' / ' + theta + ' / ' + value);
                        this.$emit("decode", value, cardnumber, cardtype, cardside);
                    }
                }
            );
        }
    }
};
</script>

<style scoped>
video {
    max-width: 100%;
    max-height: 100%;
}
.scanner-container {
    position: relative;
}
.overlay-element {
    position: absolute;
    top: 0;
    width: 100%;
    height: 99%;
    background: rgba(30, 30, 30, 0.5);
    -webkit-clip-path: polygon(
        0% 0%,
        0% 100%,
        20% 100%,
        20% 20%,
        80% 20%,
        80% 80%,
        20% 80%,
        20% 100%,
        100% 100%,
        100% 0%
    );
    clip-path: polygon(
        0% 0%,
        0% 100%,
        20% 100%,
        20% 20%,
        80% 20%,
        80% 80%,
        20% 80%,
        20% 100%,
        100% 100%,
        100% 0%
    );
}
.laser {
    width: 60%;
    margin-left: 20%;
    background-color: tomato;
    height: 1px;
    position: absolute;
    top: 40%;
    z-index: 2;
    box-shadow: 0 0 4px red;
    -webkit-animation: scanning 2s infinite;
    animation: scanning 2s infinite;
}
@-webkit-keyframes scanning {
    50% {
        -webkit-transform: translateY(75px);
        transform: translateY(75px);
    }
}
@keyframes scanning {
    50% {
        -webkit-transform: translateY(75px);
        transform: translateY(75px);
    }
}
</style>